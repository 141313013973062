import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import './assets/css/nucleo-icons.css';
import './assets/scss/blk-design-system-react.scss';
import './assets/demo/demo.css';

import Index from './views/Index.tsx';
import LandingPage from './views/pages/LandingPage.tsx';
import RegisterPage from './views/pages/RegisterPage.tsx';
import ProfilePage from './views/pages/ProfilePage.tsx';
import ServicesPage from './views/pages/ServicesPage.tsx';
import AboutUsPage from './views/pages/AboutUsPage.tsx';
import CareersPage from './views/pages/CareersPage.tsx';
import RequestServicePage from './views/pages/RequestServicePage.tsx';
import LoginPage from './views/pages/LoginPage.tsx';

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Index />} />
          <Route path="/landing" element={<LandingPage />} />
          <Route path="/register" element={<RegisterPage />} />
          <Route path="/profile" element={<ProfilePage />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/careers" element={<CareersPage />} />
          <Route path="/about-us" element={<AboutUsPage />} />
          <Route path="/request-service" element={<RequestServicePage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
