import React from 'react';
import classnames from 'classnames';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  Label,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import IndexNavbar from '../../components/Navbars/IndexNavbar';

export default function CareersPage() {
  React.useEffect(() => {
    document.body.classList.toggle('register-page');
    return function cleanup() {
      document.body.classList.toggle('register-page');
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">Under Construction</div>
        </div>
      </div>
    </>
  );
}
