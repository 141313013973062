import React from 'react';

import IndexNavbar from '../components/Navbars/IndexNavbar';
import PageHeader from '../components/PageHeader/PageHeader';
// import Footer from '../components/Footer/Footer';

// import Basics from './IndexSections/Basics.tsx';
// import Navbars from './IndexSections/Navbars.tsx';
// import Tabs from './IndexSections/Tabs.tsx';
// import Pagination from './IndexSections/Pagination.tsx';
// import Notifications from './IndexSections/Notifications.tsx';
// import Typography from './IndexSections/Typography.tsx';
// import JavaScript from './IndexSections/JavaScript.tsx';
// import NucleoIcons from './IndexSections/NucleoIcons.tsx';
// import Signup from './IndexSections/Signup.tsx';
// import Examples from './IndexSections/Examples.tsx';
// import Download from './IndexSections/Download.tsx';

export default function Index() {
  React.useEffect(() => {
    document.body.classList.toggle('index-page');
    return function cleanup() {
      document.body.classList.toggle('index-page');
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <PageHeader />
        <div className="main">
          {/* <Basics />
                    <Navbars />
                    <Tabs />
                    <Pagination />
                    <Notifications />
                    <Typography />
                    <JavaScript />
                    <NucleoIcons />
                    <Signup />
                    <Examples />
                    <Download /> */}
        </div>
        {/* <Footer /> */}
      </div>
    </>
  );
}
