import React, { ChangeEvent, FormEvent } from 'react';
import classnames from 'classnames';

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from 'reactstrap';

import IndexNavbar from '../../components/Navbars/IndexNavbar';
import { userService } from '../../services';

export default function LoginPage() {
  let reveal = false;
  const togglePasswordVisibility = () => {
    reveal = !reveal;
  };
  const [formData, setFormData] = React.useState({
    email: '',
    password: '',
    rememberMe: false,
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      const { email, password } = formData;
      const response = await userService.login({ email, password });
      console.log('Response from server:', response);
    } catch (error: any) {
      console.error('Error occurred during form submission:', error);
    }
  };
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [passwordFocus, setPasswordFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.toggle('register-page');
    return function cleanup() {
      document.body.classList.toggle('register-page');
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <Card className="card-register">
                    <CardHeader>
                      <CardImg alt="..." src={require('../../assets/img/square-purple-1.png')} />
                      <CardTitle tag="h4">Login</CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form id="form" className="form" onSubmit={(e) => handleSubmit(e)}>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            onFocus={(e) => setEmailFocus(true)}
                            onBlur={(e) => setEmailFocus(false)}
                            onChange={(e) => handleChange(e)}
                            name="email"
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': passwordFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-lock-circle" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Password"
                            type={reveal ? 'text' : 'password'}
                            onFocus={(e) => setPasswordFocus(true)}
                            onBlur={(e) => setPasswordFocus(false)}
                            onChange={(e) => handleChange(e)}
                            name="password"
                          />
                          <InputGroupAddon addonType="append">
                            {/* <Button color="secondary" onClick={togglePasswordVisibility}>
                              {reveal ? <div>r</div> : <div>!</div>}
                            </Button> */}
                          </InputGroupAddon>
                        </InputGroup>
                        <InputGroup style={{ marginLeft: '20px' }} check>
                          <Input style={{ marginTop: '4px' }} type="checkbox" name="rememberMe" />
                          <span className="form-check-sign">Remember Me</span>
                        </InputGroup>
                      </Form>
                      <Button form="form" type="submit" className="btn-round" color="primary" size="lg">
                        Login
                      </Button>
                      <a href="/">
                        <Button type="button" className="btn-round" color="secondary" size="lg">
                          Back
                        </Button>
                      </a>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <div className="register-bg" />
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
