import React, { ChangeEvent, FormEvent } from 'react';
import classnames from 'classnames';

import {
  Card,
  CardHeader,
  CardBody,
  CardImg,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
  Button,
} from 'reactstrap';

import IndexNavbar from '../../components/Navbars/IndexNavbar';
import { userService } from '../../services/index.ts';

export default function RequestServicePage() {
  const handleChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  const [formData, setFormData] = React.useState({
    email: '',
    contactNumber: '',
    companyName: '',
    description: '',
  });
  const handleSubmit = async (event: FormEvent<HTMLFormElement>): Promise<void> => {
    event.preventDefault();

    try {
      const response = await userService.requestService({ ...formData });
      console.log('Response from server:', response);
    } catch (error: any) {
      console.error('Error occurred during form submission:', error);
    }
  };
  const [emailFocus, setEmailFocus] = React.useState(false);
  const [companyNameFocus, setCompanyNameFocus] = React.useState(false);
  const [contactNumberFocus, setContactNumberFocus] = React.useState(false);
  const [descriptionFocus, setDescriptionFocus] = React.useState(false);
  React.useEffect(() => {
    document.body.classList.toggle('register-page');
    return function cleanup() {
      document.body.classList.toggle('register-page');
    };
  }, []);
  return (
    <>
      <IndexNavbar />
      <div className="wrapper">
        <div className="page-header">
          <div className="page-header-image" />
          <div className="content">
            <Container>
              <Row>
                <Col className="offset-lg-0 offset-md-3" lg="5" md="6">
                  <Card className="card-register">
                    <CardHeader style={{ paddingBottom: 0 }} >
                      <CardImg
                        style={{ width: '450px', marginTop: '-25%' }}
                        alt="..."
                        src={require('../../assets/img/square4.png')}
                      />
                      <CardTitle color="black" tag="h4">
                        request service
                      </CardTitle>
                    </CardHeader>
                    <CardBody>
                      <Form id="form" className="form" onSubmit={(e) => handleSubmit(e)}>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': emailFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-email-85" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Email"
                            type="text"
                            required
                            onFocus={(e) => setEmailFocus(true)}
                            onBlur={(e) => setEmailFocus(false)}
                            onChange={(e) => handleChange(e)}
                            name="email"
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': companyNameFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-laptop" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Company Name"
                            type="text"
                            required
                            onFocus={(e) => setCompanyNameFocus(true)}
                            onBlur={(e) => setCompanyNameFocus(false)}
                            onChange={(e) => handleChange(e)}
                            name="companyName"
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': contactNumberFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-compass-05" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Contact #"
                            type="number"
                            required
                            className="remove-stepper"
                            onFocus={(e) => setContactNumberFocus(true)}
                            onBlur={(e) => setContactNumberFocus(false)}
                            onChange={(e) => handleChange(e)}
                            name="contactNumber"
                          />
                        </InputGroup>
                        <InputGroup
                          className={classnames({
                            'input-group-focus': descriptionFocus,
                          })}
                        >
                          <InputGroupAddon addonType="prepend">
                            <InputGroupText>
                              <i className="tim-icons icon-paper" />
                            </InputGroupText>
                          </InputGroupAddon>
                          <Input
                            placeholder="Briefly Describe the Service You Need"
                            type="textarea"
                            required
                            onFocus={(e) => setDescriptionFocus(true)}
                            onBlur={(e) => setDescriptionFocus(false)}
                            onChange={(e) => handleChange(e)}
                            name="description"
                          />
                        </InputGroup>
                        <Button color="primary">Send Request</Button>
                      </Form>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
    </>
  );
}
