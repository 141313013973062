import ReactDOM from 'react-dom/client';

import './assets/css/nucleo-icons.css';
import './assets/scss/blk-design-system-react.scss';
import './assets/demo/demo.css';

import App from './App.tsx';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(<App />);
