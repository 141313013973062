type UserLoginRequest = {
  email: string;
  password: string;
};

type ServiceRequest = {
  email: string;
  contactNumber: string;
  companyName: string;
  description: string;
};

export class UserService {
  async login(data: UserLoginRequest): Promise<void> {
    console.log('logging in...', { ...data });
  }

  async requestService(data: ServiceRequest): Promise<void> {
    console.log('request service...', { ...data });
    console.log('redirecting...');
  }
}
